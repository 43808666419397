import Glide, { Controls, Breakpoints, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export default function init() {
  const roots = document.querySelectorAll('.news-slider');

  [...roots].forEach((root) => {
    const slider = new Glide(root.querySelector('.glide'), {
      gap: 0,
      perView: 3,
      breakpoints: {
        1200: { perView: 1 },
      },
    }).mount({ Controls, Breakpoints, Swipe });

    const groupedBullets = root.querySelectorAll('.glide__bullets--grouped .glide__bullet');
    const singledBullets = root.querySelectorAll('.glide__bullets--singled .glide__bullet');

    const updateActiveIndex = () => {
      const active = slider.index;

      [...groupedBullets].forEach((item) => { item.classList.remove('is--active'); });
      groupedBullets[Math.floor(active / 3)].classList.add('is--active');

      [...singledBullets].forEach((item) => { item.classList.remove('is--active'); });
      singledBullets[active].classList.add('is--active');
    };

    updateActiveIndex();

    slider.on('move', updateActiveIndex);

    [...groupedBullets, ...singledBullets].forEach((bullet) => {
      bullet.addEventListener('click', (e) => {
        e.preventDefault();
        slider.go(bullet.dataset.glideDir);
      });
    });
  });
}
