import navPanel from '../../components/nav-panel/nav-panel';
import navMobile from '../../components/nav-mobile/nav-mobile';
import topBar from '../../components/top-bar/top-bar';
import newsTicker from '../../blocks/news-ticker/news-ticker';
import newsSlider from '../../blocks/news-slider/news-slider';
import textSlider from '../../blocks/text-slider/text-slider';

const appStart = () => {
  navPanel.init();
  navMobile.init();
  topBar.init();

  newsTicker();
  newsSlider();
  textSlider();

  const scrollToNextSection = document.querySelectorAll('[data-action="scroll-to-next-section"]');
  const scrollToTop = document.querySelectorAll('[data-action="scroll-to-top"]');

  [...scrollToNextSection].forEach((btn) => {
    btn.addEventListener('click', () => {
      const currentSection = btn.closest('section');
      const nextSection = currentSection.nextElementSibling;

      nextSection.scrollIntoView({ behavior: 'smooth', block: 'center' });
    });
  });

  [...scrollToTop].forEach((btn) => {
    btn.addEventListener('click', () => { window.scrollTo({ top: 0, behavior: 'smooth' }); });
  });
};

// 'DOMContentLoaded' may fire before your script has a chance to run.
if (document.readyState === 'loading') {
  document.addEventListener('DOMContentLoaded', appStart);
} else {
  appStart();
}
