import Glide, { Controls, Autoplay, Swipe } from '@glidejs/glide/dist/glide.modular.esm';

export default function init() {
  const roots = document.querySelectorAll('.text-slider');

  [...roots].forEach((root) => {
    new Glide(root.querySelector('.glide'), {
      gap: 0,
      autoplay: 5000,
    }).mount({ Controls, Swipe, Autoplay });
  });
}
