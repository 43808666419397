import Headroom from 'headroom.js';

class TopBar {
  constructor({
    // Default options.
    target = '.top-bar',
  } = {}) {
    this.target = document.querySelector(target);
    this.headroomInstance = null;
  }

  mount() {
    if (this.target) {
      this.headroomInstance = new Headroom(this.target, {
        offset: 300,
        tolerance: 5,
      });

      this.headroomInstance.init();
    }
  }
}

function init() {
  new TopBar().mount();
}

export default {
  init,
};
