import Glide, { Autoplay, Controls } from '@glidejs/glide/dist/glide.modular.esm';

export default function init() {
  const roots = document.querySelectorAll('.news-ticker');

  [...roots].forEach((root) => {
    const timeout = parseInt(root.dataset.timeout, 10);
    const glideRoot = root.querySelector('.glide');

    new Glide(glideRoot, {
      autoplay: timeout || 5000,
      gap: 0,
    }).mount({ Controls, Autoplay });
  });
}
